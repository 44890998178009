import React, {useEffect, useState, useMemo} from 'react'

import Container from 'react-bootstrap/Container'
import BlogPreview from './blog-preview'
import Slider from "react-slick";

import Blog2 from '../assets/images/Blog2.png'
import blackArrow from '../assets/images/nextIcon-black.png'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Blogroll = props => {

    const [width, setWidth] = useState(null)

    const slidesToShow = useMemo(() => {
        if(width >= 1100) { return 3 } 
        else if(width >= 601 && width <= 1100) {return 2 }
        else if(width <= 600) { return 1 }
        else { return 2}
    },[width])

    const setWindowSize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(()=> {
        setWindowSize()
        window.addEventListener('resize', setWindowSize);
    },[])


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1
    };

    const {media } = props



    return (
        <div id="blog-roll">
            <Container>
                <Slider {...settings}>
                    {media.length > 0 ? media.map( m => {
                        return(
                            <BlogPreview title={m.node.title} date={m.node.date} link={m.node.file ? m.node.file.file.url : '/media'} image={m.node.image ? m.node.image.file.url : Blog2} />
                        )
                    }) : <p>No Media</p>}
                </Slider>
                <div  style={{margin:'100px 0'}}className="arrow-link arrow-link-black"> <a href="/media">VIEW ALL MEDIA<img src={blackArrow} /></a></div>
            </Container>
        </div>

    )
}

export default Blogroll


