import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

const Slider = (props) => {

  const { slides } = props

  return (
  <Carousel id="hero"
    indicators={true}
    nextIcon={<span aria-hidden="true" className="custom-carousel-next-icon" />
    }
    prevIcon={<span aria-hidden="true" className="custom-carousel-prev-icon" />
    }
  >
    { slides.map(slide => {
    return(
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={slide.node.image.file.url}
        alt={slide.node.title}
      />
      <Container>
      <Carousel.Caption className="vertical-middle">
        <h2>{slide.node.title}</h2>
        <h3>{slide.node.text}</h3>
        <a href={slide.node.link}> <Button className="cta-button-banner">{slide.node.cta}</Button></a>
      </Carousel.Caption>
      </Container>
    </Carousel.Item>
    )
    })
}
  </Carousel>
  )
}

export default Slider

