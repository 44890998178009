import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import greenArrow from '../assets/images/nextIconGreen.png'
import about from '../assets/images/about.png'



const FeaturedMedia = (props) => {
    const { article } = props
    const item = article.node
    const image = item.image.file.url
    const trimmedBlurb = item.blurb.blurb.split(" ").splice(0,50).join(" ").concat("...")
    const date = new Date(item.date)
    const formattedDate = date.toLocaleDateString("en-US")

return(
    <Container>
    <Row id="featured-media-row" className="d-none d-lg-flex" style={{backgroundImage:`url(${image})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'right'}} >
            <Col className="content-col">
                <h3>{formattedDate}</h3>
                <h2>{item.title}</h2>
                <p>{trimmedBlurb}</p>
                <div className="arrow-link"> <a target="_new" href={item.file.file.url}>LEARN MORE<img src={greenArrow} /></a></div>
            </Col>
            <Col>
            </Col>
    </Row>
    <Row id="featured-media-row-mobile" className="space d-lg-none d-xl-none">
            <Col lg="6">
                <img src={image}/>
            </Col>
            <Col lg="6" className="content-col">
                <h3>{formattedDate}</h3>
                <h2>{item.title}</h2>
                <p>{trimmedBlurb}
                </p>
                <div className="arrow-link"> <a target="_new" href={item.file.file.url}>LEARN MORE<img src={greenArrow} /></a></div>
            </Col>
         
    </Row>
    </Container>
)
}
export default FeaturedMedia
