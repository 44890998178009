import React from 'react'
import Moment from 'moment'

const BlogPreview = props => {
    const { image, title, date, link } = props
    const dateToFormat = new Moment(date);
    
dateToFormat.format('MM/dd/YYYY')
    return(
        <div className="blog-preview">
            <img src={image}/>
            <p className="blog-date">{dateToFormat.format('MMMM DD, YYYY')}</p>
            <p className="blog-title">{title}</p>
            <a href={link} target="_blank" className="blog-link">READ MORE</a>
        </div>
    )

}

export default BlogPreview

