import React, { useState, useEffect, useMemo } from 'react'
import Slider from "react-slick";
import Container from "react-bootstrap/Container"

import clienthome1 from '../assets/images/clienthome1.jpg'
import clienthome2 from '../assets/images/clienthome2.jpg'
import clienthome3 from '../assets/images/clienthome3.jpg'
import clienthome4 from '../assets/images/clienthome4.jpg'
import clienthome5 from '../assets/images/clienthome5.jpg'
import clienthome6 from '../assets/images/clienthome6.jpg'
import clienthome7 from '../assets/images/clienthome7.jpg'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ClientsHome = () => {

    const [width, setWidth] = useState(null)

    const slidesToShow = useMemo(() => {
        if(width >= 1100) { return 4 } 
        else if(width >= 601 && width <= 1100) {return 2 }
        else if(width <= 600) { return 1 }
        else { return 2}
    },[width])

    const setWindowSize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(()=> {
        setWindowSize()
        window.addEventListener('resize', setWindowSize);
    },[])

    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div id="clients-home">
            <Container>
                <Slider {...settings}>
                    <img src={clienthome1}/>
                    <img src={clienthome2}/>
                    <img src={clienthome3}/>
                    <img src={clienthome4}/>
                    <img src={clienthome5}/>
                    <img src={clienthome6}/>
                    <img src={clienthome7}/>
                </Slider>
                </Container>
        </div>

    )
}

export default ClientsHome


