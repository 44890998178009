import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import health from '../assets/images/Health.png'
import technology from '../assets/images/Technology.png'
import professional from '../assets/images/Professional.png'
import greenArrow from '../assets/images/nextIconGreen.png'

import LazyLoad from 'react-lazyload';




export default () => (
    <Row id="services-row">
        <Container>
            <Col>
                <Row noGutters>
                    <Col md="1">
                        <img className="services-icon" src={health} />
                    </Col>
                    <Col md="3">
                        <h3>HEALTH</h3>
                        <p className="homepage-service-text">We create solutions to keep our customers ahead of the curve while ensuring best practices.</p>
                        <div className="arrow-link"> <a href="/services">LEARN MORE<img src={greenArrow} /></a></div>
                    </Col>
                    <Col md="1">
                        <img className="services-icon" src={technology} />
                    </Col>
                    <Col md="3">
                        <h3>TECHNOLOGY</h3>
                        <p className="homepage-service-text">We build custom technology solutions that make sense individually for our customers.</p>
                        <div className="arrow-link"> <a href="/services">LEARN MORE<img src={greenArrow} /></a></div>
                    </Col>
                    <Col md="1">
                        <img className="services-icon" src={professional} />
                    </Col>
                    <Col md="3">
                        <h3>PROFESSIONAL SERVICES</h3>
                        <p className="homepage-service-text">We combine industry best practice with innovative solutions for service delivery success.</p>
                        <div className="arrow-link"> <a href="/services">LEARN MORE<img src={greenArrow} /></a></div>
                    </Col>
                </Row>
            </Col>
        </Container>
    </Row>
)

