import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Slider from '../components/slider'
import Services from '../components/services'
import FeaturedMedia from '../components/featured-media'
import Process from '../components/process'
import CtaBar from '../components/cta-bar'
import BlogRoll from '../components/blog-roll'
import Testimonials from '../components/testimonials'
import ClientsHome from '../components/clients-home-page'



class RootIndex extends React.Component {
  render() {

    const media = get(this, 'props.data.allContentfulMedia.edges')
    const slides = get(this, 'props.data.allContentfulHeroSlide.edges')
    const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
    let featured = media[0]

      media.map(m => {
        if(m.node.featuredPost == 'yes') {
        featured = m;
        }
      })
    
  
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Slider slides={slides}/>
          <Services/>
          <FeaturedMedia article={featured}/>
          <Process/>
          <CtaBar/>
          <BlogRoll media={media}/>
          <Testimonials testimonials={testimonials}/>
          <ClientsHome/>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulMedia(sort: {fields: date, order: DESC}, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          id
          title
          date
          featuredPost
          blurb {
            blurb
          }
          image {
            file {
              url
            }
          }
          file {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulHeroSlide(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          title
          text
          link
          cta
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulTestimonial(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          title
          quote
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
