import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import greenArrow from '../assets/images/nextIconGreen.png'
import process from '../assets/images/process.png'





export default () => (
    <Container>
    <Row id="process-row"className="d-none d-lg-flex">
            <Col>
            </Col>
            <Col className="content-col">
                <h3>OUR PROCESS</h3>
                <h2>How We Do It</h2>
                <p>At V-Tech we are committed to showing up with our game <b>F.A.C.E.</b>: Focus, Accountability, Commitment, Execution. 
                    We <b>Focus</b> on your success and satisfaction; show <b>Accountability</b> for our work and service delivery; 
                    demonstrate <b>Commitment</b> to excellent service; validate <b>Execution</b> of all work on 
                    time and on budget while staying aligned with your mission.
                </p>
                <div className="arrow-link"> <a href="/about">LEARN MORE<img src={greenArrow} /></a></div>
            </Col>
    </Row>
    <Row id="process-row-mobile" className="space d-lg-none d-xl-none">
    <Col lg="6" >
            <img src={process}/>
            </Col>
            <Col lg="6" className="content-col">
                <h3>OUR PROCESS</h3>
                <h2>How We Do It</h2>
                <p>At V-Tech we are committed to showing up with our game <b>F.A.C.E.</b>: Focus, Accountability, Commitment, Execution. 
                    We <b>Focus</b> on your success and satisfaction; show <b>Accountability</b> for our work and service delivery; 
                    demonstrate <b>Commitment</b> to excellent service; validate <b>Execution</b> of all work on 
                    time and on budget while staying aligned with your mission.
                </p>
                <div className="arrow-link"> <a>LEARN MORE<img src={greenArrow} /></a></div>
            </Col>
          
    </Row>
    </Container>
)

